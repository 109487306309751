import { useEffect, useState } from 'react';
import { useEmailRenderContext } from '../components/EmailRenderContext';

/**
 * A utility hook to generate images and keep the generated image in a
 * state variable.
 */

/* Eslint thinks we're using a hook in a callback here,
 * while in reality this is a factory function. So it's
 * safe to dsiable this rule here. */

/* eslint-disable react-hooks/rules-of-hooks */
const useImageGenerator = (generator) => (...args) => {
  const [image, setImage] = useState(null);
  const { registerLoadingItem } = useEmailRenderContext();

  const argValues = JSON.stringify(args);

  const executeGenerator = async () => {
    const removeLoadingItem = registerLoadingItem();
    const generatedImage = await generator(...args);
    setImage(generatedImage);
    removeLoadingItem();
  };

  useEffect(() => {
    executeGenerator();
  }, [generator, argValues]);

  return image;
};

export default useImageGenerator;
