const locale = {
  zip: {
    loading: 'Preperando el archivo ZIP',
    downloaded: 'Se está descargando el archivo ZIP',
    error: 'Hubo un error al preparar el archivo ZIP. DSI ha sido notificado sobre este error.'
  },
  mailchimp: {
    title: 'Publicar en Mailchimp',
    description: 'Por favor, ingresa el ID de la campaña de Mailchimp en la cuál quieres publicar.',
    loading: 'Enviando paquete a Mailchimp',
    noId: 'No se ha ingresado un ID de campaña válido. Los IDs de campaña sólo consisten en números.',
  },
  email: {
    rendering: 'Rendereando email.'
  },
  button: {
    cancel: 'Cancelar',
    publish: 'Publicar',
  },
  imageInput: {
    imageTooLarge: (x) =>
      `La imagen es muy grande, el tamaño máximo es de ${x} MB`,
  },
  browserHint: 'Esta función está optimizada para Google Chrome y es posible que no funcione en otros navegadores.',
  showButton: 'Previsualizar y publicar Email',
};

export default locale;
