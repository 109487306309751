import React from 'react';

// Renders a div with a conditional comment that can be parsed in postprocessing
// to only include the conditonal comment
// because JSX has no other way of adding conditonal comments.
const ConditionalComment = ({ comment }) => {
  return (
    <span data-conditional-comment={comment} style={{ display: 'none' }} />
  );
};

export default ConditionalComment;
