import React from 'react';
import Table, { Tr, Td } from './Table';

import { emailSpacingSmall } from '../../constants/email';

const TwoColumns = ({
  children,
  height,
  padding = emailSpacingSmall,
  columns = ['50%', '50%'],
  verticalAlign = 'top',
  verticalAlignLeft,
  verticalAlignRight,
  background
}) => {
  const valign = [
    verticalAlignLeft || verticalAlign,
    verticalAlignRight || verticalAlign
  ];

  return (
    <Table background={background} style={{ backgroundColor: background }}>
      <Tr>
        {React.Children.map(children, (c, i) => (
          <Td
            verticalAlign={valign[i]}
            width={columns[i]}
            className="column"
            style={{
              paddingLeft: i === 0 ? 0 : padding,
              paddingRight: i === 0 ? padding : 0
            }}
          >
            {c}
          </Td>
        ))}
      </Tr>
    </Table>
  );
};

export default TwoColumns;
