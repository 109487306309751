import React from 'react';

export const Swatch = ({ color = '', style }) => {
  const border = color.toLowerCase() === '#ffffff' ? '#eee' : 'transparent';
  return (
    <div
      style={{
        width: 22,
        height: 22,
        marginRight: 16,
        border: `1px solid ${border}`,
        ...style,
        backgroundColor: color
      }}
    />
  );
};

export default Swatch;
