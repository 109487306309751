import React from 'react';

import useImage from '../../util/useImage';

import Block from './Block';
import Table, { Td, Tr } from './Table';
import Button from './Button';
import VerticalSpacer from './VerticalSpacer';

import { emailHeadline, emailSpacingLarge } from '../../constants/email';

const Artwork = ({ theme, headline, image, height, url }) => {
  const { cardArtworkBackground, cardArtworkText } = theme;

  const artworkImage = useImage(image, { maxWidth: 170 });

  return (
    <Block
      title="Una obra"
      background={cardArtworkBackground}
      color={cardArtworkText}
      height={height}
    >
      <Table height="100%">
        <Tr>
          <Td>
            <h3 style={{ ...emailHeadline, color: cardArtworkText }}>
              {headline}
            </h3>
          </Td>
        </Tr>
        <Tr>
          <Td verticalAlign="bottom">
            <VerticalSpacer space={emailSpacingLarge} />
            <Table>
              <Tr>
                <Td verticalAlign="bottom" width="50%">
                  <Button
                    label="Ir a la obra"
                    href={url}
                    color={cardArtworkText}
                    background={cardArtworkBackground}
                  />
                </Td>
                <Td verticalAlign="bottom" width="50%">
                  {artworkImage && (
                    <img
                      width="170"
                      style={{
                        width: '170px',
                        maxWidth: '100%',
                        height: 'auto'
                      }}
                      alt=""
                      src={artworkImage}
                      className="image"
                    />
                  )}
                </Td>
              </Tr>
            </Table>
          </Td>
        </Tr>
      </Table>
    </Block>
  );
};

export default Artwork;
