import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { externalLink, getByString } from '../../util';
import {
  TabbedForm,
  TabbedFormTabs,
  FormTab,
  NumberInput,
  SelectInput,
  TextInput,
  BooleanInput,
  Button,
  ArrayInput,
  DateInput,
  SimpleFormIterator,
  ImageInput,
  ImageField,
  FunctionField,
  FormDataConsumer,
  useGetOne
} from 'react-admin';

import {
  RichTextInput,
  RichTextInputToolbar,
  FormatButtons,
  LinkButtons,
  ClearButtons
} from 'ra-input-rich-text';

import { ColorInput } from 'react-admin-color-picker';
import { useNotify } from 'react-admin';

import PreviewIcon from '@mui/icons-material/Preview';

import Issue from '../Issue';
import locale from '../../../content/locale';

import { baseColorLabels } from '../../../content/schema/issue';

import Swatch from './Swatch';

import './admin.css';
import { PRODUCTION_URL, STAGING_URL } from './constants';

const Row = ({ children }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'stretch', width: '100%' }}>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          key: index,
          style: {
            marginLeft: index > 0 ? 16 : null
          }
        });
      })}
    </div>
  );
};

const ThemeColorSelect = ({ source, label, ...otherProps }) => {
  return (
    <FormDataConsumer>
      {({ formData }) => {
        const { colors = {} } = formData;

        return (
          <SelectInput
            {...otherProps}
            source={source}
            label={label}
            defaultValue="#ffffff"
            emptyValue="#ffffff"
            emptyText={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Swatch color={'#ffffff'} /> <span>Blanco</span>
              </span>
            }
            optionText={choice => {
              return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <Swatch color={choice.id} />{' '}
                  <span>{baseColorLabels[choice.name] || choice.name}</span>
                </span>
              );
            }}
            choices={Object.keys(colors).map(k => ({
              id: colors[k],
              name: k
            }))}
          />
        );
      }}
    </FormDataConsumer>
  );
};

const FieldForKey = ({ field, label, settings }) => {
  const otherProps = {
    label: field.label || label,
    fullWidth: true,
    style: { maxWidth: 900 },
    className: 'maxWidth900',
    defaultValue: field.defaultValue,
    helperText: field.helperText
  };

  const toolbar = (
    <RichTextInputToolbar>
      <FormatButtons />
      <LinkButtons />
      <ClearButtons />
    </RichTextInputToolbar>
  );

  const notify = useNotify();

  switch (field.type) {
    case 'text':
      return field.html ? (
        <RichTextInput {...otherProps} toolbar={toolbar} source={field.name} />
      ) : (
        <TextInput
          {...otherProps}
          multiline={field.multiline}
          source={field.name}
        />
      );
    case 'number':
      return <NumberInput {...otherProps} source={field.name} />;
    case 'date':
      return (
        <DateInput
          {...otherProps}
          style={{ maxWidth: 400 }}
          source={field.name}
        />
      );
    case 'select':
      return (
        <SelectInput
          {...otherProps}
          source={field.name}
          choices={field.options.map(o => ({
            id: o,
            name: o
          }))}
        />
      );
    case 'themeColorSelect':
      return <ThemeColorSelect source={field.name} {...otherProps} />;
    case 'boolean':
      return <BooleanInput source={field.name} label={field.label || label} />;
    case 'image':
      const maxSize = 4000000;
      return (
        <>
          <ImageInput
            source={field.name}
            label={field.label || label || 'Imagen'}
            accept="image/*"
            maxSize={maxSize}
            style={otherProps.style}
            options={{
              onDropRejected: () => {
                notify(locale.imageInput.imageTooLarge(maxSize / 1000000), {
                  type: 'error'
                });
              }
            }}
          >
            <ImageField source="url" title="id" />
          </ImageInput>
          <FunctionField
            label="Name"
            style={{ marginBottom: '2em' }}
            render={record => {
              const img = getByString(field.name, record);
              return img ? img.src : '';
            }}
          />
        </>
      );
    case 'artworkInfo':
      return (
        <ArrayInput
          source={field.name}
          label={field.label}
          style={otherProps.style}
        >
          <SimpleFormIterator className="artworkInfoFields">
            <TextInput
              {...otherProps}
              label={'Característica'}
              source="label"
            />
            <div style={{ width: 30 }} />
            <TextInput
              {...otherProps}
              label={'Descripción'}
              source="description"
            />
          </SimpleFormIterator>
        </ArrayInput>
      );
    case 'mixBlock':
      return (
        <ArrayInput
          source={field.name}
          label={'Bloques'}
          style={otherProps.style}
        >
          <SimpleFormIterator>
            <TextInput {...otherProps} label="Etiqueta" source="label" />
            <ThemeColorSelect
              {...otherProps}
              source="labelBackground"
              label={'Color de la etiqueta'}
            />

            <TextInput {...otherProps} label="Título" source="headline" />
            <RichTextInput
              {...otherProps}
              toolbar={toolbar}
              multiline
              html
              label="Texto"
              source="text"
            />
            <TextInput {...otherProps} label="Texto Link" source="cta" />
            <TextInput {...otherProps} label="Link" source="url" type="url" />
          </SimpleFormIterator>
        </ArrayInput>
      );
    case 'person':
      return (
        <div
          style={{
            border: '1px solid #ccc',
            width: '100%',
            padding: '0 16px',
            marginBottom: 24,
            ...otherProps.style
          }}
        >
          <h4 style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 16 }}>Por</h4>
          <TextInput
            {...otherProps}
            source={`${field.name}.name`}
            label="Nombre"
          />
          <Row>
            <TextInput
              {...otherProps}
              source={`${field.name}.role`}
              label="Rol"
            />
            <TextInput
              {...otherProps}
              source={`${field.name}.company`}
              label="Empresa"
            />
          </Row>
        </div>
      );
    case 'colors':
      return (
        <>
          <h3>Paleta de color</h3>
          <div
            className="maxWidth900"
            style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 24 }}
          >
            {Object.keys(field.baseColors).map((key, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 16
                }}
              >
                <FormDataConsumer>
                  {({ formData }) => (
                    <Swatch
                      color={formData[field.name]?.[key]}
                      style={{ marginTop: '1em' }}
                    />
                  )}
                </FormDataConsumer>
                <ColorInput
                  className="ra-color-input"
                  source={`${field.name}.${key}`}
                  key={index}
                  label={key}
                  defaultValue={field.baseColors[key]}
                />
              </div>
            ))}
          </div>
        </>
      );
    default:
      return (
        <p style={{ marginBottom: '2rem' }}>
          No form field for {field.type} yet.
        </p>
      );
  }
};

const SchemaForm = ({ schema, sectionLabels, validation }) => {
  const {
    data: settings,
    isLoading,
    error,
    refetch
  } = useGetOne('settings', {
    id: 'index.json'
  });

  const sections = {
    issue: {
      label: 'issue',
      fields: [
        <TextInput
          fullWidth
          key={'000'}
          disabled
          source="id"
          style={{ display: 'none' }}
        />,
        <div key={'0001'}>
          <FormDataConsumer>
            {({ formData }) => (
              <div style={{ display: 'flex', columnGap: 20, marginBottom: 20 }}>
                <Button
                  variant="outlined"
                  label={'Abrir en Preview'}
                  href={STAGING_URL + formData.slug}
                  {...externalLink}
                />
                <Button
                  variant="outlined"
                  label={'Abrir en Producción'}
                  href={PRODUCTION_URL + formData.slug}
                  {...externalLink}
                />
              </div>
            )}
          </FormDataConsumer>
        </div>
      ]
    }
  };
  schema.forEach((field, fieldIndex) => {
    const parts = field.name.split('.');
    const section = parts.length > 1 ? parts.shift() : 'issue';

    if (!sections[section]) {
      sections[section] = {
        label: sectionLabels[section] || section,
        fields: []
      };
    }

    sections[section].fields.push(
      <FieldForKey
        key={field.name + fieldIndex}
        field={field}
        label={parts.join(' ')}
        settings={settings}
        helper={
          isLoading ? (
            'Loading...'
          ) : error ? (
            <>
              Error cargando lista de temas{' '}
              <button onClick={e => e.preventDefault() && refetch()}>
                reintentar
              </button>
            </>
          ) : null
        }
      />
    );
  });

  return (
    <TabbedForm
      tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
      warnWhenUnsavedChanges
      style={{ alignItems: 'stretch' }}
      resolver={yupResolver(validation)}
    >
      {Object.values(sections).map((section, index) => {
        return (
          <FormTab key={index} label={section.label}>
            {section.fields}
          </FormTab>
        );
      })}
      <FormTab
        label={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <PreviewIcon />
            Preview Web
          </span>
        }
      >
        <FormDataConsumer>
          {({ formData }) => <Issue issue={formData} />}
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  );
};

export default SchemaForm;
