import { useStaticQuery, graphql } from 'gatsby';

export const useSiteAndSettings = () => {
  const data = useStaticQuery(graphql`
    query {
      settingsJson {
        ...SettingsData
      }
      site {
        ...SiteMetadata
      }
    }
  `);

  return { settings: data.settingsJson, site: data.site.siteMetadata };
};
