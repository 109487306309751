import React from 'react';

import Block from './Block';
import VerticalSpacer from './VerticalSpacer';

import {
  emailHeadlineFontFamily,
  emailMediumFontSize,
  emailLargeFontSize,
  emailSpacingLarge,
  emailCaption
} from '../../constants/email';

const quoteStyle = {
  fontFamily: emailHeadlineFontFamily,
  fontSize: emailMediumFontSize,
  lineHeight: 1.25
};

const quotationStyle = { fontSize: emailLargeFontSize, lineHeight: 0 };

const Quote = ({ theme, quote, author, subline, height, className }) => {
  const { cardHighlightBackground, cardHighlightText } = theme;

  return (
    <Block
      title="Una cita"
      background={cardHighlightBackground}
      color={cardHighlightText}
      height={height}
      className={className}
    >
      <div style={{ ...quoteStyle, color: cardHighlightText }}>
        <span style={quotationStyle}>“</span>
        {quote}
        <span style={quotationStyle}>”</span>
      </div>
      <VerticalSpacer space={emailSpacingLarge} />
      <div style={{ ...emailCaption, color: cardHighlightText }}>
        {author}
        <br />
        <em>{subline}</em>
      </div>
    </Block>
  );
};

export default Quote;
