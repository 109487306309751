import React, { useRef, useEffect } from 'react';
import {
  useRecordContext,
  useShowContext,
  List,
  Datagrid,
  Edit,
  Create,
  FunctionField,
  DateField,
  EditButton,
  ShowButton,
  Show
} from 'react-admin';

import SchemaForm from './schemaForm';
import capitalize from 'lodash/capitalize';

import { EmailRenderContextProvider } from '../EmailRenderContext';

import PublishEmail from './PublishEmail';
import LoadingScreen from './LoadingScreen';

import {
  IssueValidationSchema,
  IssueFormSchema,
  CreateIssueFormSchema,
  CreateIssueValidationSchema,
  sectionLabels
} from '../../../content/schema/issue';

import BookIcon from '@mui/icons-material/Book';
import EmailIcon from '@mui/icons-material/Email';

import locale from '../../../content/locale';

export const IssueIcon = BookIcon;

export const IssueList = props => (
  <List sort={{ field: '_ragInfo.createdAt', order: 'DESC' }} {...props}>
    <Datagrid>
      <FunctionField render={issue => capitalize(issue._ragInfo.slug)} />{' '}
      <DateField source="_ragInfo.createdAt" label="Creado" showTime />
      <ShowButton label={locale.showButton} icon={<EmailIcon />} />
      <EditButton />
    </Datagrid>
  </List>
);

const ShowIssue = () => {
  const hasFetched = useRef(null);
  const data = useRecordContext();
  const { isFetching } = useShowContext();

  useEffect(() => {
    if (!isFetching) {
      hasFetched.current = true;
    }
  }, [isFetching]);

  if (isFetching && !hasFetched.current) {
    return <LoadingScreen />;
  }

  return (
    <EmailRenderContextProvider>
      <PublishEmail data={data} />
    </EmailRenderContextProvider>
  );
};

export const IssueShow = () => {
  return (
    <Show title="Preview" emptyWhileLoading>
      <ShowIssue />
    </Show>
  );
};

const IssueTitle = ({ record }) => {
  return <span> Issue {record ? `"${record.id}"` : ''} </span>;
};

export const IssueEdit = props => {
  return (
    <div>
      <Edit title={<IssueTitle />} {...props}>
        <SchemaForm
          schema={IssueFormSchema}
          sectionLabels={sectionLabels}
          validation={IssueValidationSchema}
        />{' '}
      </Edit>{' '}
    </div>
  );
};

export const IssueCreate = props => (
  <Create title="Create a new Issue" {...props}>
    <SchemaForm
      schema={CreateIssueFormSchema}
      sectionLabels={sectionLabels}
      validation={CreateIssueValidationSchema}
    />{' '}
  </Create>
);
