import React from 'react';

import Tr from './Tr';
import Td from './Td';

const Table = ({
  children,
  cellSpacing = 0,
  cellPadding = 0,
  width = '100%',
  ...rest
}) => {
  return (
    <table
      cellSpacing={cellSpacing}
      cellPadding={cellPadding}
      border={0}
      width={width}
      {...rest}
    >
      <tbody>{children}</tbody>
    </table>
  );
};

export { Tr, Td };
export default Table;
