import React from 'react';

import useImage from '../../util/useImage';

import Table, { Tr, Td } from './Table';

import {
  emailCaption,
  emailSpacingLarge,
  emailSpacingSmall,
  emailFakeBorderRadius
} from '../../constants/email';

import { isLightColor } from '../../util/functions';

const Block = ({
  children,
  verticalAlign = 'top',
  align = 'left',
  title,
  background,
  color,
  style,
  bodyPadding = emailSpacingLarge,
  className,
  height = '100%',
  ...rest
}) => {
  const borderRadius = emailFakeBorderRadius;

  const icon = useImage({
    src: isLightColor(color) ? '/iconWhite.png' : '/iconDark.png',
  }, { format: 'image/png', maxWidth: 20 });

  return (
    <Table
      style={{
        ...style
      }}
      className={className}
      {...rest}
    >
      {borderRadius.map((i, index) => (
        <Tr key={index}>
          <Td
            horizontalAlign="center"
            style={{
              paddingLeft: `${i}px`,
              paddingRight: `${i}px`,
              height: '1px',
              lineHeight: '1px',
              fontSize: '1px'
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'block',
                height: '1px',
                backgroundColor: background,
                lineHeight: '1px',
                fontSize: '1px'
              }}
            >
              &nbsp;
            </div>
          </Td>
        </Tr>
      ))}
      {title && (
        <Tr>
          <Td
            bgcolor={background}
            style={{
              backgroundColor: background,
              color,
              paddingLeft: emailSpacingLarge,
              paddingTop: emailSpacingSmall,
              paddingBottom: 0
            }}
          >
            <Table className="hideOnGmail">
              <Tr>
                <Td width={32} verticalAlign="middle">
                  <img
                    src={icon}
                    width={20}
                    height={20}
                    style={{ height: '20px', width: '20px' }}
                    alt=""
                  />
                </Td>
                <Td verticalAlign="middle">
                  <h2
                    style={{
                      color,
                      margin: 0,
                      lineHeight: 1,
                      ...emailCaption
                    }}
                  >
                    {title}
                  </h2>
                </Td>
              </Tr>
            </Table>
            <Table
              style={{
                display: 'none',
                msoHide: 'all'
              }}
              className="showOnGmail"
            >
              <Tr>
                <Td>
                  <h2
                    style={{
                      color,
                      margin: 0,
                      lineHeight: 1,
                      ...emailCaption
                    }}
                  >
                    {title}
                  </h2>
                </Td>
              </Tr>
            </Table>
          </Td>
        </Tr>
      )}
      <Tr>
        <Td
          bgColor={background}
          height={height}
          className="block"
          align={align}
          verticalAlign={verticalAlign}
          style={{
            color,
            textAlign: align,
            padding: bodyPadding,
            backgroundColor: background
          }}
        >
          {children}
        </Td>
      </Tr>
      {borderRadius
        .slice()
        .reverse()
        .map((i, index) => (
          <Tr key={index}>
            <Td
              horizontalAlign="center"
              style={{
                paddingLeft: `${i}px`,
                paddingRight: `${i}px`,
                height: '1px',
                lineHeight: '1px',
                fontSize: '1px'
              }}
            >
              <div
                style={{
                  display: 'block',
                  height: '1px',
                  backgroundColor: background,
                  lineHeight: '1px',
                  fontSize: '1px'
                }}
              >
                &nbsp;
              </div>
            </Td>
          </Tr>
        ))}
    </Table>
  );
};

export default Block;
