export const hashFromString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash.toString();
};

export const validateCampaignId = (id) => {
  const re = /^[0-9]+$/;
  return re.test(String(id).toLowerCase());
};
