import { defaultTheme } from 'react-admin';

// Make React Admin look less like google
export const theme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    background: {
      default: '#E8ECF2',
    },
    primary: {
      main: '#081226',
      contrastText: '#ffffff',
    },
    secondary: {
      contrastText: '#ffffff',
      main: '#03050D',
    },
  },
  components: {
    ...defaultTheme.components,
    RaAppBar: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          height: '72px',
          color: '#ffffff',
          backgroundColor: '#332644',
          boxShadow: 'none',
        },
      },
    },
    RaLogin: {
      styleOverrides: {
        root: {
          background: '#E8ECF2',
          '& .RaLogin-card': {
            backgroundColor: '#ffffff',
            boxShadow: 'none',
          },
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          paddingTop: '20px',
        },
      },
    },
    RaTopToolbar: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          minHeight: '72px !important',
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    RaImageInput: {
      styleOverrides: {
        root: {
          '& .RaFileInput-dropZone': {
            padding: '1rem 1.5rem',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            background: '#ffffff',
            cursor: 'pointer',
            textAlign: 'left',
          },
          '& .RaFileInput-dropZone:hover': {
            background: '#c5ddff',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '& .MuiCardContent-root': {
            paddingTop: '16px',
            paddingBottom: '16px',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff !important',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          '& .MuiInputBase-input': {
            background: 'transparent',
          },
        },
      },
      defaultProps: {
        variant: 'standard',
        disableUnderline: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    button: {
      textTransform: 'none',
      letterSpacing: '0.0125em',
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem',
    },
  },
};
