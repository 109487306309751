import React from 'react';
import useImage from '../../util/useImage';

import Block from './Block';
import Button from './Button';
import Table, { Td, Tr } from './Table';
import VerticalSpacer from './VerticalSpacer';

import {
  emailHeadline,
  emailCaption,
  emailSpacingSmall,
  emailSpacingLarge
} from '../../constants/email';

const Book = ({ theme, headline, byLine, book, height, className, url }) => {
  const { cardLightBackground, cardLightText, cardLightTitle } = theme;

  const preview = useImage(book.image, { maxWidth: 160 });

  return (
    <Block
      title="Un libro"
      background={cardLightBackground}
      color={cardLightText}
      height={height}
      className={className}
    >
      <Table>
        <Tr>
          <Td width="47%">
            <h3 style={{ ...emailHeadline, color: cardLightTitle }}>
              {headline}
            </h3>
            <VerticalSpacer space={emailSpacingSmall} />
            <div
              style={{
                ...emailCaption
              }}
            >
              <span style={{ color: cardLightTitle }}>Por {byLine.name}</span>
              <br />
              {byLine.role}
              {byLine.role && byLine.company && ', '}
              {byLine.company}
            </div>

            <VerticalSpacer space={emailSpacingLarge} />

            <Button
              href={url}
              label="Ir a reseña"
              color={cardLightText}
              background={cardLightBackground}
            />
          </Td>
          <Td width="6%"></Td>
          <Td width="47%">
            {preview && (
              <img
                width={160}
                alt=""
                style={{ display: 'block', width: '160px', maxWidth: '100%' }}
                src={preview}
                className="image"
              />
            )}
            <VerticalSpacer space={emailSpacingSmall} />
            <div style={{ ...emailCaption }}>
              <strong>{book.title}</strong>
              <br />
              {book.author}
              <br />
              {book.info}
            </div>
          </Td>
        </Tr>
      </Table>
    </Block>
  );
};

export default Book;
