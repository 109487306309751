import * as yup from 'yup';

import { yupColor } from './utils';

export const defaultBannerColors = {
  colorBackground: '#ccada9',
  colorLabel: '#e2d6d6',
  colorText: '#000000'
};

export const SettingsValidationSchema = yup.object().shape({
  hideHomepage: yup.boolean(),
  about: yup.object().shape({
    text: yup.string().required()
  }),
  share: yup.object().shape({
    email: yup.object().shape({
      label: yup.string().required(),
      subject: yup.string().required(),
      text: yup.string().required()
    }),
    twitter: yup.object().shape({
      label: yup.string().required(),
      text: yup.string().required()
    }),
    whatsapp: yup.object().shape({
      label: yup.string().required(),
      text: yup.string().required()
    }),
    linkedin: yup.object().shape({
      label: yup.string().required(),
      text: yup.string().required()
    })
  }),
  subscribe: yup.object().shape({
    url: yup.string().required(),
    fallbackUrl: yup.string().required(),
    show: yup.boolean(),
    label: yup.string().required(),
    title: yup.string().required(),
    cta: yup.string().required(),
    inputLabel: yup.string().required(),
    validationError: yup.string().required(),
    successTitle: yup.string().required(),
    successText: yup.string().required(),
    errorTitle: yup.string().required(),
    errorText: yup.string().required()
  }),
  banner: yup.object().shape({
    show: yup.boolean(),
    label: yup.string().required(),
    title: yup.string().required(),
    subtitle: yup.string(),
    date: yup.string().required(),
    location: yup.string().required(),
    cta: yup.string().required(),
    url: yup.string().required(),
    colorBackground: yupColor.default(defaultBannerColors.colorBackground),
    colorLabel: yupColor.default(defaultBannerColors.colorLabel),
    colorText: yupColor.default(defaultBannerColors.colorText)
  })
});
