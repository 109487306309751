import * as React from 'react';
import { createElement } from 'react';
import {
  Admin,
  Resource,
  Menu,
  Layout,
  useResourceDefinitions
} from 'react-admin';
import {
  buildAuthProvider,
  buildDataProvider
} from '@designsystemsinternational/react-admin-github';
import {
  IssueList,
  IssueEdit,
  IssueCreate,
  IssueIcon,
  IssueShow
} from './issues';
import { ReleaseList, ReleaseCreate, ReleaseIcon } from './releases';
import { UserList, UserEdit, UserCreate, UserIcon } from './users';
import { SettingEdit, SettingIcon } from './settings';

import { theme } from '../../util/adminTheme';

const authProvider = buildAuthProvider(process.env.GATSBY_PROXY_URL);
const dataProvider = buildDataProvider(process.env.GATSBY_PROXY_URL, {
  resources: {
    issues: {
      handler: 'json',
      filenameFromProperty: 'title'
    },
    settings: {
      handler: 'json',
      disableTimestamp: true
    }
  }
});

const AdminPage = () => {
  return (
    <Admin
      layout={CustomLayout}
      authProvider={authProvider}
      dataProvider={dataProvider}
      theme={theme}
    >
      <Resource
        name="issues"
        list={IssueList}
        edit={IssueEdit}
        create={IssueCreate}
        icon={IssueIcon}
        show={IssueShow}
      />
      <Resource
        name="releases"
        list={ReleaseList}
        create={ReleaseCreate}
        icon={ReleaseIcon}
      />
      <Resource
        name="users"
        list={UserList}
        edit={UserEdit}
        create={UserCreate}
        icon={UserIcon}
      />
      <Resource name="settings" edit={SettingEdit} icon={SettingIcon} />
    </Admin>
  );
};

const CustomLayout = props => <Layout {...props} menu={CustomMenu} />;

const CustomMenu = () => {
  const resources = useResourceDefinitions();
  return (
    <Menu>
      {Object.keys(resources)
        .filter(name => name !== 'settings')
        .map(name => (
          <Menu.Item
            key={name}
            to={`/${name}`}
            primaryText={
              (resources[name].options && resources[name].options.label) || name
            }
            leftIcon={createElement(resources[name].icon)}
          />
        ))}
      <Menu.Item
        to="/settings/index.json"
        primaryText="settings"
        leftIcon={<SettingIcon />}
      />
    </Menu>
  );
};

export default AdminPage;
