import React, { useRef, useState, useEffect, useMemo } from 'react';

import { useNotify } from 'react-admin';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { buildThemeFromColors } from '../../util/theme';
import { wrapperFactory } from '../../util/email';
import { useEmailRenderContext } from '../EmailRenderContext';
import { validateCampaignId } from '../../util/string';

import IssueEmail from '../IssueEmail';
import BrowserBanner from './BrowserBanner';
import locale from '../../../content/locale';

import {
  prepareEmailMarkup,
  createZipDownload,
  publishToMailchimp
} from '../../util/email';

import * as css from './PublishEmail.module.css';

export default function PublishEmail({ data }) {
  const renderContainer = useRef(null);
  const [html, setHtml] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [campaignId, setCampaignId] = useState(() => data?.email?.campaignId);

  const { isRendering } = useEmailRenderContext();
  const notify = useNotify();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const emailTheme = buildThemeFromColors(data.colors);
  const { background } = emailTheme;
  const wrapper = useMemo(() => wrapperFactory(background), [background]);

  const handleZip = async () => {
    try {
      setIsLoading(locale.zip.loading);
      const download = await createZipDownload(html, data.slug);
      setIsLoading(null);
      await download();
      notify(locale.zip.downloaded, { type: 'success' });
    } catch (e) {
      notify(locale.zip.error, { type: 'error' });
      throw new Error(e);
    }
  };

  const handleMailchimp = async () => {
    closeModal();

    if (!campaignId || !validateCampaignId(campaignId)) {
      return notify(locale.mailchimp.noId, { type: 'error' });
    }

    setIsLoading(locale.mailchimp.loading);

    await publishToMailchimp({
      body: html,
      id: campaignId,
      onSuccess: msg => {
        setIsLoading(null);
        notify(msg, { type: 'success' });
      },
      onError: msg => {
        setIsLoading(null);
        notify(msg, { type: 'error' });
      }
    });
  };

  useEffect(() => {
    const updateIframePreview = async () => {
      const html = await prepareEmailMarkup(
        renderContainer.current.innerHTML,
        wrapper
      );
      setHtml(html);
    };

    if (!isRendering) {
      updateIframePreview();
      setIsLoading(null);
    } else {
      setIsLoading(locale.email.rendering);
    }
  }, [isRendering, wrapper]);

  return (
    <div className={css.root}>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            backgroundColor: 'white',
            width: '50%',
            maxWidth: '600px',
            margin: '100px auto',
            padding: '20px'
          }}
        >
          <Stack spacing={2}>
            <div>
              <Typography variant="h6" component="h2">
                {locale.mailchimp.title}
              </Typography>
              <Typography variant="body2" component="p">
                {locale.mailchimp.description}
              </Typography>
            </div>

            <TextField
              label="Mailchimp ID"
              value={campaignId}
              variant="filled"
              onChange={e => setCampaignId(e.target.value)}
            />

            <Stack
              direction="row"
              spacing={2}
              sx={{ marginTop: '20px', justifyContent: 'flex-end' }}
            >
              <Button variant="outlined" onClick={closeModal}>
                {locale.button.cancel}
              </Button>
              <Button variant="contained" onClick={handleMailchimp}>
                {locale.button.publish}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>

      <Backdrop sx={{ zIndex: 1000 }} open={isLoading}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            color: 'white'
          }}
        >
          <CircularProgress color="inherit" />
          <div>{isLoading}</div>
        </Stack>
      </Backdrop>

      <BrowserBanner />

      <Stack spacing={1}>
        <Toolbar
          sx={{
            gap: '1rem',
            marginBottom: '20px',
            borderBottom: '1px solid #e1e1e1'
          }}
        >
          <Button
            onClick={openModal}
            disabled={isLoading || isRendering}
            variant="contained"
            color="primary"
            startIcon={<UploadIcon />}
          >
            Enviar a Mailchimp
          </Button>
          <Button
            disabled={isRendering}
            onClick={handleZip}
            variant="text"
            startIcon={<DownloadIcon />}
          >
            Descargar email como ZIP
          </Button>
        </Toolbar>
        <div ref={renderContainer} className={css.renderContainer} hidden>
          <IssueEmail issue={data} />
        </div>
        <div
          className={css.renderPreview}
          aria-hidden={isRendering ? true : null}
        >
          <iframe className={css.iframe} title="E-Mail Preview" srcDoc={html} />
        </div>
      </Stack>
    </div>
  );
}
