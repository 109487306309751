import { hashFromString } from './string';

/**
 * Loads a webfont and returns a deterministic name to use the webfont.
 *
 * @param {string} path - absolute URL of the webfont
 * @returns {string} name - deterministically hashed name to use the webfont
 */
const loadWebfontForCanvas = async (path) => {
  const name = `font-${hashFromString(path)}`;

  const fontFile = new FontFace(name, `url(${path})`);
  document.fonts.add(fontFile);

  await fontFile.load();

  return name;
};

export default loadWebfontForCanvas;
