import React from 'react';

import useImage from '../../util/useImage';

const Header = ({ background }) => {
  const refraccionesLogo = useImage(
    { src: '/refracciones.png' },
    { maxWidth: 300, format: 'image/png' }
  );

  const btgLogo = useImage(
    { src: '/btg.png' },
    { maxWidth: 160, format: 'image/png' }
  );

  return (
    <table
      background={background}
      role="presentation"
      style={{ width: '100%', backgroundColor: background }}
    >
      <tr>
        <td
          width="53%"
          valign="middle"
          align="left"
          style={{
            textAlign: 'left'
          }}
        >
          <img
            src={refraccionesLogo}
            width="300"
            alt=""
            style={{
              width: '300px',
              height: 'auto',
              border: 'none'
            }}
          />
        </td>
        <td width="12%"></td>
        <td width="35%" valign="middle" align="right">
          <img
            src={btgLogo}
            width="160"
            alt=""
            style={{
              width: '160px',
              height: 'auto',
              border: 'none'
            }}
          />
        </td>
      </tr>
    </table>
  );
};

export default Header;
