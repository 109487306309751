import React from 'react';
import { emailSpacingLarge } from '../../constants/email';

const Paragraph = ({ children, color, style }) => {
  const processedChildren = children
    .replace(
      /<p>/gm,
      `<p style="margin-bottom: ${emailSpacingLarge}; margin-top: 0;">`
    )
    .replace(/<span[^/>]*>/gm, '<span>');

  return (
    <div
      style={{
        ...style,
        color
      }}
      dangerouslySetInnerHTML={{ __html: processedChildren }}
    />
  );
};

export default Paragraph;
