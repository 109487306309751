import * as React from 'react';
import md5 from 'md5';

import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  FunctionField,
  TextField,
  ImageField,
  EditButton,
  TextInput,
  PasswordInput
} from 'react-admin';
import GroupIcon from '@mui/icons-material/Group';
export const UserIcon = GroupIcon;

export const UserList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="_ragInfo.name" label="Email" />
      <EditButton />
    </Datagrid>
  </List>
);

const UserTitle = ({ record }) => {
  return <span>User {record ? `"${record.fullName}"` : ''}</span>;
};

const generateAvatarUrl = user => {
  const hash = md5(user.id.trim().toLowerCase());
  return {
    ...user,
    avatar: `https://secure.gravatar.com/avatar/${hash}`
  };
};

export const UserEdit = props => {
  return (
    <Edit title={<UserTitle />} {...props}>
      <SimpleForm style={{ alignItems: 'stretch', maxWidth: 600 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ImageField source="avatar" title="avatar" width={30} />
          <FunctionField
            render={user => user.id.replace(/\.json$/, '')}
            label="Email"
            sx={{ fontSize: 16, fontWeight: 'bold', marginLeft: 2 }}
            fullWidth
          />
        </div>
        <TextInput source="fullName" label="Full name" fullWidth />
        <PasswordInput source="password" fullWidth />
      </SimpleForm>
    </Edit>
  );
};

export const UserCreate = props => (
  <Create title="Create a User" {...props} transform={generateAvatarUrl}>
    <SimpleForm style={{ alignItems: 'stretch', maxWidth: 600 }}>
      <TextInput source="id" label="Email" fullWidth />
      <TextInput source="fullName" label="Full name" fullWidth />
      <PasswordInput source="password" fullWidth />
    </SimpleForm>
  </Create>
);
