import React from 'react';

import { emailSpacingSmall } from '../../constants/email';

const VerticalSpacer = ({ space = emailSpacingSmall, style, className }) => {
  return (
    <div
      style={{
        ...style,
        lineHeight: space,
        height: space
      }}
      className={className}
    >
      &#8202;
    </div>
  );
};

export default VerticalSpacer;
