import React from 'react';

import { getImage } from '../util';
import { useSiteAndSettings } from '../util/queries/siteAndSettings';
import { buildThemeFromColors } from '../util/theme';

import { emailMaxWidth } from '../constants/email';
import Container from '../components/email/Container';

import Header from './email/Header';
import Letter from './email/Letter';
import Intro from './email/Intro';
import TwoColumns from './email/TwoColumns';
import Quote from './email/Quote';
import Book from './email/Book';
import Podcast from './email/Podcast';
import Artwork from './email/Artwork';
import Mix from './email/Mix';
import Conversation from './email/Conversation';
import Playlist from './email/Playlist';
import Footer from './email/Footer';
import VerticalSpacer from './email/VerticalSpacer';
import GhostBreak from './email/GhostBreak';

import { emailSpacingLarge } from '../constants/email';

const IssueEmail = ({ issue }) => {
  const { site } = useSiteAndSettings();
  const { siteUrl } = site;

  const theme = buildThemeFromColors(issue.colors);
  const { background } = theme;

  if (!issue.email) return null;
  const anchorUrl = anchor => {
    return `${siteUrl}/${issue.slug}${anchor ? `#${anchor}` : ''}`;
  };
  return (
    <Container background={background} maxWidth={emailMaxWidth}>
      <VerticalSpacer space="10px" />
      <Header background={background} />
      <VerticalSpacer space="40px" />
      {issue.email.showLetter && (
        <>
          <Letter
            theme={theme}
            title={issue.email.title}
            text={issue.email.text}
            byLine={issue.email.byLine}
          />
          <VerticalSpacer space={emailSpacingLarge} />
        </>
      )}
      {issue.intro.show && (
        <>
          <Intro
            image={getImage(issue.intro.image)}
            theme={theme}
            headline={issue.intro.headline}
            text={issue.intro.email}
            number={issue.number}
            title={issue.title}
            url={anchorUrl('introduccion')}
          />
          <VerticalSpacer space={emailSpacingLarge} />
        </>
      )}

      <TwoColumns background={background}>
        {issue.quote.show && (
          <>
            <Quote
              theme={theme}
              quote={issue.quote.text}
              author={issue.quote.author.name}
              subline={issue.quote.author.subline}
              height="360px"
            />
            <VerticalSpacer space={emailSpacingLarge} />
          </>
        )}
        {issue.book.show && (
          <>
            <Book
              height="360px"
              theme={theme}
              headline={issue.book.headline}
              byLine={issue.book.byLine}
              url={anchorUrl('libro')}
              book={{
                ...issue.book.book,
                image: getImage(issue.book.book.image)
              }}
            />
            <VerticalSpacer space={emailSpacingLarge} />
          </>
        )}
      </TwoColumns>

      <TwoColumns background={background}>
        {issue.podcast.show && (
          <>
            <Podcast
              height="400px"
              theme={theme}
              headline={issue.podcast.title}
              byLine={issue.podcast.byLine}
              text={issue.podcast.email}
              image={getImage(issue.podcast.image)}
              url={anchorUrl('podcast')}
            />
            <VerticalSpacer space={emailSpacingLarge} />
          </>
        )}
        {issue.artwork.show && (
          <>
            <Artwork
              height="400px"
              theme={theme}
              headline={issue.artwork.title}
              image={getImage(issue.artwork.imageEmail || issue.artwork.image)}
              url={anchorUrl('obra')}
            />
            <VerticalSpacer space={emailSpacingLarge} />
          </>
        )}
      </TwoColumns>

      {issue.mix.show && (
        <>
          <Mix
            theme={theme}
            image={getImage(issue.mix.image)}
            imagePosition={issue.mix.imagePosition}
            headline={issue.mix.title}
            byLine={issue.mix.byLine}
            text={issue.mix.email}
            url={anchorUrl('mix')}
          />
          <GhostBreak />
          <VerticalSpacer space={emailSpacingLarge} />
        </>
      )}
      {issue.conversation.show && (
        <>
          <Conversation
            theme={theme}
            image={getImage(issue.conversation.image)}
            headline={issue.conversation.title}
            meta={issue.conversation.meta}
            text={issue.conversation.text}
            url={anchorUrl('conversacion')}
          />
          <VerticalSpacer space={emailSpacingLarge} />
        </>
      )}

      {issue.playlist?.show && (
        <>
          <Playlist
            theme={theme}
            byLine={issue.playlist.byLine}
            image={issue.playlist.image && getImage(issue.playlist.image)}
            headline={issue.playlist.title}
            intro={issue.playlist.intro}
            url={anchorUrl('playlist')}
          />
          <VerticalSpacer space={emailSpacingLarge} />
        </>
      )}

      <Footer background={background} />
      <VerticalSpacer space="50px" />
    </Container>
  );
};

export default IssueEmail;
