import React from 'react';

import TextToImage from './TextToImage';
import Block from './Block';
import Button from './Button';
import Table, { Td, Tr } from './Table';
import Paragraph from './Paragraph';
import VerticalSpacer from './VerticalSpacer';

import useImage from '../../util/useImage';

import {
  emailXLargeFontSize,
  emailMediumFontSize,
  emailSpacingSmall,
  emailSpacingLarge,
  emailBodyText,
  emailHeadline
} from '../../constants/email';

const Intro = ({ theme, image, number, title, headline, text, url }) => {
  const { cardLightBackground, cardLightText, cardLightTitle } = theme;

  const flagImage = useImage(image, {
    maxWidth: 200,
    format: 'image/png',
    afterProcessing: ({ ctx, width, height }) => {
      ctx.globalCompositeOperation = 'destination-in';
      ctx.beginPath();
      ctx.moveTo(0, height * 0.5);
      ctx.lineTo(width, height);
      ctx.lineTo(width, 0);
      ctx.lineTo(0, height * 0.5);
      ctx.fill();
    }
  });

  return (
    <Block
      title={'Una idea'}
      background={cardLightBackground}
      color={cardLightText}
      bodyPadding={0}
    >
      <Table>
        <Tr>
          <Td
            width={30}
            style={{
              paddingLeft: emailSpacingLarge,
              paddingTop: emailSpacingLarge
            }}
          >
            <span style={{ ...emailBodyText, fontSize: emailMediumFontSize }}>
              ({number})
            </span>
          </Td>
          <Td
            style={{
              paddingLeft: emailSpacingSmall,
              paddingTop: emailSpacingLarge
            }}
          >
            <TextToImage
              text={title.toUpperCase()}
              size={emailXLargeFontSize}
              color={cardLightText}
              backgroundColor={cardLightBackground}
              density={4}
              width="300px"
              maxWidth="80%"
            />
          </Td>
          {image && (
            <Td width={'25%'} rowSpan={2} colSpan={2}>
              {flagImage && (
                <img
                  width="200"
                  src={flagImage}
                  data-clip-image="0 50%, 100% 100%, 100% 0"
                  style={{
                    width: '200px',
                    maxWidth: '100%'
                  }}
                  alt=""
                />
              )}
            </Td>
          )}
        </Tr>
        <Tr>
          <Td
            colSpan={2}
            style={{
              paddingLeft: emailSpacingLarge,
              paddingBottom: emailSpacingLarge
            }}
          >
            <h2
              className="headline"
              style={{
                color: cardLightTitle,
                ...emailHeadline
              }}
            >
              {headline}
            </h2>
          </Td>
        </Tr>
        <Tr>
          <Td
            colSpan={4}
            style={{
              paddingRight: emailSpacingLarge,
              paddingLeft: emailSpacingLarge
            }}
          >
            <Table width="500" className="row">
              <Tr>
                <Td>
                  <Paragraph
                    style={{
                      ...emailBodyText,
                      paddingRight: emailSpacingLarge
                    }}
                  >
                    {text}
                  </Paragraph>
                </Td>
              </Tr>
            </Table>
          </Td>
        </Tr>
        <Tr>
          <Td
            horizontalAlign="right"
            colSpan={4}
            style={{
              paddingRight: emailSpacingLarge,
              paddingLeft: emailSpacingLarge,
              paddingBottom: emailSpacingLarge
            }}
          >
            <VerticalSpacer space={emailSpacingLarge} />
            <Button
              href={url}
              label="Ir a introducción"
              background={cardLightBackground}
              color={cardLightText}
            />
          </Td>
        </Tr>
      </Table>
    </Block>
  );
};

export default Intro;
