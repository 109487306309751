import React, { createContext, useContext, useState, useRef } from 'react';

export const EmailRenderContext = createContext();

export const EmailRenderContextProvider = ({ children }) => {
  const loadingItems = useRef(new Set());
  const [isRendering, setIsRendering] = useState(false);

  const registerLoadingItem = () => {
    const id = Symbol();
    loadingItems.current.add(id);

    if (!isRendering) {
      setIsRendering(true);
    }

    return () => {
      loadingItems.current.delete(id);
      setIsRendering(loadingItems.current.size > 0);
    };
  };

  return (
    <EmailRenderContext.Provider value={{ registerLoadingItem, isRendering }}>
      {children}
    </EmailRenderContext.Provider>
  );
};

export const useEmailRenderContext = () => {
  const context = useContext(EmailRenderContext);
  if (!context) {
    throw new Error(
      'useEmailRenderContext must be used within a EmailRenderContextProvider',
    );
  }
  return context;
};
