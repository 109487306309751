import React from 'react';
import useImage from '../../util/useImage';

import Block from './Block';
import Paragraph from './Paragraph';
import Button from './Button';
import Table, { Tr, Td } from './Table';

import VerticalSpacer from './VerticalSpacer';

import {
  emailHeadline,
  emailCaption,
  emailBodyText,
  emailSpacingSmall,
  emailSpacingXLarge
} from '../../constants/email';

const Podcast = ({ theme, headline, byLine, text, height, image, url }) => {
  const { cardDarkBackground, cardDarkText } = theme;

  const preview = useImage(image, { maxWidth: 160, format: 'image/png' });

  return (
    <Block
      title="Un podcast"
      background={cardDarkBackground}
      color={cardDarkText}
      height={height}
    >
      <Table height="100%">
        <Tr>
          <Td>
            <h3 style={{ ...emailHeadline, color: cardDarkText }}>
              {headline}
            </h3>
          </Td>
        </Tr>
        <Tr>
          <Td>
            <VerticalSpacer space={emailSpacingSmall} />
            <div
              style={{
                ...emailCaption
              }}
            >
              Por {byLine.name} <br />
              {byLine.role}
              {byLine.role && byLine.company && ','}
              {byLine.company}
            </div>
            <VerticalSpacer space={emailSpacingXLarge} />
            <Table>
              <Tr>
                <Td width="47%">
                  {preview && (
                    <img
                      width={160}
                      alt=""
                      style={{
                        display: 'block',
                        margin: '0 auto',
                        width: '160px',
                        maxWidth: '100%'
                      }}
                      src={preview}
                    />
                  )}
                </Td>
                <Td width="6%"></Td>
                <Td width="47%">
                  <Paragraph style={emailBodyText}>{text}</Paragraph>
                </Td>
              </Tr>
            </Table>
          </Td>
        </Tr>
        <Tr>
          <Td verticalAlign="bottom">
            <VerticalSpacer space={emailSpacingSmall} />
            <Button
              href={url}
              label="Ir al podcast"
              background={cardDarkBackground}
              color={cardDarkText}
            />
          </Td>
        </Tr>
      </Table>
    </Block>
  );
};

export default Podcast;
