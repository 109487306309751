import React from 'react';

import ConditionalComment from './ConditionalComment';

const GhostBreak = ({ props }) => {
  return (
    <ConditionalComment comment={`<!--[if gte mso 12]><br /> <![endif]-->`} />
  );
};

export default GhostBreak;
