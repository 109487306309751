import * as React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  SettingsValidationSchema,
  defaultBannerColors
} from '../../../content/schema/settings';

import {
  Edit,
  TabbedForm,
  TabbedFormTabs,
  FormTab,
  TextInput,
  BooleanInput,
  FormDataConsumer
} from 'react-admin';

import {
  RichTextInput,
  RichTextInputToolbar,
  FormatButtons,
  ClearButtons
} from 'ra-input-rich-text';

import { ColorInput } from 'react-admin-color-picker';
import Swatch from './Swatch';

import SettingsIcon from '@mui/icons-material/Settings';
import { externalLink } from '../../util';
export const SettingIcon = SettingsIcon;

export const SettingEdit = props => (
  <Edit title="Settings" {...props} redirect={false}>
    <TabbedForm
      tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
      warnWhenUnsavedChanges
      style={{ alignItems: 'stretch' }}
      resolver={yupResolver(SettingsValidationSchema)}
    >
      <FormTab label={'General'}>
        <BooleanInput source={'hideHomepage'} label="Esconder Homepage" />
      </FormTab>
      <FormTab label={'About'}>
        <RichTextInput
          fullWidth
          stripTags
          style={{ maxWidth: 900 }}
          className="maxWidth900"
          toolbar={
            <RichTextInputToolbar>
              <FormatButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          label="Texto about"
          source={'about.text'}
        />
      </FormTab>
      <FormTab label={'Compartir'}>
        <TextInput disabled source="id" fullWidth />

        <h3>Email</h3>
        <TextInput
          source="share.email.label"
          label="Email share label"
          fullWidth
        />

        <TextInput
          source="share.email.subject"
          label="Asunto del email"
          fullWidth
        />
        <TextInput
          source="share.email.text"
          label="Texto del email"
          fullWidth
        />

        <h3 component="h3">Twitter</h3>
        <TextInput
          source="share.twitter.label"
          label="Twitter share label"
          fullWidth
        />
        <TextInput
          source="share.twitter.text"
          label="Texto del tweet"
          fullWidth
        />

        <h3 component="h3">WhatsApp</h3>

        <TextInput
          source="share.whatsapp.label"
          label="Whatsapp share label"
          fullWidth
        />

        <TextInput
          source="share.whatsapp.text"
          label="Texto del mensaje de WhatsApp"
          fullWidth
        />

        <h3 component="h3">LinkedIn</h3>
        <TextInput
          source="share.linkedin.label"
          label="Etiqueta compartir en LinkedIn"
          fullWidth
        />
        <TextInput
          source="share.linkedin.text"
          label="Texto del post"
          fullWidth
        />
      </FormTab>
      <FormTab label={'Formulario Suscripción'}>
        <BooleanInput source="subscribe.show" label="Mostrar" fullWidth />
        <TextInput source="subscribe.label" label="Etiqueta" fullWidth />
        <TextInput
          source="subscribe.title"
          multiline
          label="Título"
          fullWidth
        />
        <TextInput source="subscribe.cta" label="Botón" fullWidth />
        <TextInput
          source="subscribe.inputLabel"
          label="Etiqueta input"
          fullWidth
        />
        <TextInput
          source="subscribe.validationError"
          label="Error de validación"
          fullWidth
        />

        <TextInput
          source="subscribe.successTitle"
          label="Éxito: Título"
          fullWidth
        />
        <TextInput
          source="subscribe.successText"
          label="Éxito: Texto"
          fullWidth
        />
        <TextInput
          source="subscribe.errorTitle"
          label="Error: Título"
          fullWidth
        />
        <TextInput
          source="subscribe.errorText"
          label="Error: Texto"
          fullWidth
        />
        <TextInput
          source="subscribe.url"
          label="Url Suscripción a Mailchimp"
          fullWidth
          helperText={
            <>
              Se obtiene en{' '}
              <a
                {...externalLink}
                href="https://us19.admin.mailchimp.com/lists/dashboard/signup-forms/"
              >
                Signup forms
              </a>{' '}
              / Embedded forms
            </>
          }
        />
        <TextInput
          source="subscribe.fallbackUrl"
          label="Url Secundaria de Suscripción a Mailchimp"
          fullWidth
          helperText={
            <>
              Se obtiene en{' '}
              <a
                {...externalLink}
                href="https://us19.admin.mailchimp.com/lists/dashboard/signup-forms/"
              >
                Signup forms
              </a>{' '}
              / Form builder
            </>
          }
        />
      </FormTab>
      <FormTab label={'Banner'}>
        <BooleanInput source="banner.show" label="Mostrar" fullWidth />
        <TextInput source="banner.label" label="Etiqueta" fullWidth />
        <TextInput source="banner.title" label="Título" fullWidth />
        <TextInput source="banner.subtitle" label="Subtítulo" fullWidth />
        <TextInput source="banner.date" label="Fecha" fullWidth />
        <TextInput source="banner.location" label="Ubicación" fullWidth />
        <TextInput source="banner.cta" label="Texto link" fullWidth />
        <TextInput source="banner.url" label="Url" fullWidth />
        <div style={{ display: 'flex', gap: 12 }}>
          {['colorBackground', 'colorLabel', 'colorText'].map(
            (field, index) => (
              <div
                key={index}
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 16
                }}
              >
                <FormDataConsumer>
                  {({ formData }) => (
                    <Swatch
                      color={formData.banner[field]}
                      style={{ marginTop: '1em' }}
                    />
                  )}
                </FormDataConsumer>
                <ColorInput
                  className="ra-color-input"
                  source={`banner.${field}`}
                  key={index}
                  label={field}
                  defaultValue={defaultBannerColors[field]}
                />
              </div>
            )
          )}
        </div>
      </FormTab>
    </TabbedForm>
  </Edit>
);
