import React from 'react';
import useImage from '../../util/useImage';

import Block from './Block';
import Button from './Button';
import Paragraph from './Paragraph';
import VerticalSpacer from './VerticalSpacer';
import TwoColumns from './TwoColumns';

import {
  emailHeadline,
  emailCaption,
  emailSpacingXSmall,
  emailSpacingSmall,
  emailSpacingLarge,
  emailBodyText
} from '../../constants/email';

const Playlist = ({ theme, byLine, headline, intro, image, url }) => {
  const {
    cardArtworkBackground: cardBackground,
    cardArtworkText: cardText,
    cardArtworkTitle: cardTitle
  } = theme;

  const preview = useImage(
    image,
    { maxWidth: 340, format: 'image/png' }
  )

  return (
    <Block title="Un playlist" background={cardBackground} color={cardText}>
      <h3
        style={{
          ...emailHeadline,
          color: cardTitle
        }}
      >
        {headline}
      </h3>
      <VerticalSpacer space={emailSpacingSmall} />

      <TwoColumns padding={emailSpacingLarge}>
        <div>
          <div
            style={{
              ...emailCaption
            }}
          >
            Por {byLine.name} <br />
            {byLine.role}
            {byLine.role && byLine.company && ', '}
            {byLine.company}
          </div>
          <VerticalSpacer space={emailSpacingLarge} />
          <Paragraph style={emailBodyText}>{intro}</Paragraph>
          <VerticalSpacer space={emailSpacingLarge} />
          <Button
            href={url}
            label="Ir al playlist"
            color={cardText}
            background={cardBackground}
          />
          <VerticalSpacer space={emailSpacingLarge} />
        </div>
        <div style={{ marginTop: emailSpacingXSmall }}>
          {preview && (
            <img
              src={preview}
              style={{ width: '340px', display: 'block', maxWidth: '100%' }}
              className="image"
              width="340"
              height="auto"
              alt=""
            />
          )}
        </div>
      </TwoColumns>
    </Block>
  );
};

export default Playlist;
