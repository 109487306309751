import React from 'react';

const Td = ({
  children,
  verticalAlign = 'top',
  horizontalAlign = 'left',
  align = 'left',
  className,
  rowSpan,
  colSpan,
  style,
  width,
  height
}) => {
  return (
    <td
      valign={verticalAlign}
      align={align}
      width={width}
      height={height}
      className={className}
      rowSpan={rowSpan}
      colSpan={colSpan}
      style={{
        ...style,
        width,
        height,
        verticalAlign,
        textAlign: horizontalAlign
      }}
    >
      {children}
    </td>
  );
};

export default Td;
