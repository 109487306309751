export const emailMaxWidth = '800px';
export const emailColumnWidth = '397px'; // MAGIC NUMBER
export const emailSpacingXSmall = '5px';
export const emailSpacingSmall = '10px';
export const emailSpacingLarge = '20px';
export const emailSpacingXLarge = '40px';
export const emailDefaultFontSize = '14px';
export const emailMediumFontSize = '18px';
export const emailLargeFontSize = '24px';
export const emailXLargeFontSize = '32px';
export const emailFakeBorderRadius = [7, 5, 4, 3, 2, 1, 1];
export const emailDefaultFontFamily =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
export const emailHeadlineFontFamily =
  '"Atlantic-Medium", Optima, Candara, "Lucida Sans", "Segoe UI", "Lao MN", serif';

export const emailBodyText = {
  fontFamily: emailDefaultFontFamily,
  fontSize: emailDefaultFontSize,
  lineHeight: 1.425
};

export const emailHeadline = {
  fontFamily: emailHeadlineFontFamily,
  fontSize: emailLargeFontSize,
  lineHeight: 1.2,
  fontWeight: 400,
  margin: 0
};

export const emailCaption = {
  fontFamily: emailDefaultFontFamily,
  fontSize: '12px',
  fontWeight: 400
};
