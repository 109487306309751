import * as React from 'react';
import { Router } from '@reach/router';
import Admin from '../components/admin';

const AdminPage = () => {
  if (typeof window === 'object') {
    return (
      <Router basepath="/admin">
        <Admin path="/" />
      </Router>
    );
  } else {
    return <div>Loading</div>;
  }
};

export default AdminPage;
