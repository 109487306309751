import React from 'react';

import Block from './Block';
import Table, { Td, Tr } from './Table';
import Paragraph from './Paragraph';
import VerticalSpacer from './VerticalSpacer';

import {
  emailHeadlineFontFamily,
  emailMediumFontSize,
  emailSpacingLarge,
  emailBodyText
} from '../../constants/email';

const Letter = ({ theme, text, byLine, number, title }) => {
  const { cardLightBackground, cardLightText, cardLightTitle } = theme;

  return (
    <Block background={cardLightBackground} color={cardLightText}>
      <Table>
        <Tr>
          <Td
            style={{
              color: cardLightTitle,
              fontSize: emailMediumFontSize,
              fontFamily: emailHeadlineFontFamily,
              lineHeight: 1.25
            }}
          >
            <Paragraph>{title}</Paragraph>
          </Td>
        </Tr>
        <Tr>
          <Td
            style={{
              columns: '2 280px',
              ...emailBodyText
            }}
          >
            <Paragraph>{text}</Paragraph>
            <VerticalSpacer space={emailSpacingLarge} />
            <div
              style={{
                textAlign: 'right'
              }}
            >
              <strong>{byLine.name}</strong>
              <br />
              {byLine.role}
              <br />
              {byLine.company}
            </div>
          </Td>
        </Tr>
      </Table>
    </Block>
  );
};

export default Letter;
