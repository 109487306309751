import { useState, useEffect } from 'react';
import { useEmailRenderContext } from '../components/EmailRenderContext';

const resolveSrc = (image) => {
  if (typeof image === 'string') return image;
  if (image?.type && image.type === 'file') {
    return image.url ?? image.src;
  }
  if (image?.src) return image.src;
  return '';
}

export default function useImage(
  imgSrc,
  {
    format = 'image/jpeg',
    maxWidth = 9999,
    pixelRatio = 2.5,
    afterProcessing
  } = {}
) {
  const [image, setImage] = useState(null);

  const { registerLoadingItem } = useEmailRenderContext();
  const src = resolveSrc(imgSrc);

  const renderImg = (img, cb) => {
    const canvas = document.createElement('canvas');

    const w = Math.min(img.naturalWidth, maxWidth);
    const h = (img.naturalHeight / img.naturalWidth) * w;

    const ctx = canvas.getContext('2d');
    canvas.width = w * pixelRatio;
    canvas.height = h * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(img, 0, 0, w, h);

    if (typeof afterProcessing === 'function') {
      afterProcessing({ ctx, width: w, height: h });
    }

    cb?.call();
    setImage(canvas.toDataURL(format));
  };

  useEffect(() => {
    if (src === '' || src == null || !src) {
      setImage(null);
      return;
    }

    const resetLoading = registerLoadingItem();
    const img = document.createElement('img');
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      renderImg(img, resetLoading);
    };
    img.src = src;

    return () => {
      img.onload = null;
      resetLoading();
    };
  }, [src]);

  return image;
}
