import React from 'react';

const Container = ({ background, maxWidth, children, padding = '20px' }) => {
  // Remove px from maxWidth
  const unitLessWidth = maxWidth.replace(/px$/, '');

  return (
    <div
      role="article"
      aria-roledescription="email"
      lang="es"
      style={{
        WebkitFontSmoothing: 'antialiased',
        WebkitTextSizeAdjust: '100%',
        msTextSizeAdjust: '100%',
        backgroundColor: background
      }}
    >
      <table
        role="presentation"
        bgcolor={background}
        width="100%"
        border="0"
        cellSpacing="0"
        cellPadding="0"
        style={{
          backgroundColor: background,
          width: '100%',
          border: 0,
          borderSpacing: 0,
          borderCollapse: 'separate',
          msoTableLspace: '0pt',
          msoTableRspace: '0pt'
        }}
      >
        <tr>
          <td valign="top" align="center">
            &nbsp;
          </td>
          <td
            className="container"
            style={{
              maxWidth: maxWidth,
              display: 'block',
              verticalAlign: 'top',
              padding: `0 ${padding}`,
              margin: '0 auto',
              width: maxWidth
            }}
            width={unitLessWidth}
            valign="top"
          >
            <div
              style={{
                display: 'block',
                margin: '0 auto',
                maxWidth: maxWidth,
                padding: padding
              }}
            >
              {children}
            </div>
          </td>
          <td valign="top" align="center">
            &nbsp;
          </td>
        </tr>
      </table>
    </div>
  );
};

export default Container;
