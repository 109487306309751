import React from 'react';
import useImageGenerator from '../../util/useImageGenerator';

import loadWebfontForCanvas from '../../util/loadWebfontForCanvas';

const MAX_WIDTH_PER_CHARACTER = 48;

const imageGenerator = async ({
  text,
  size,
  color,
  backgroundColor,
  maxWidth,
  marginBottom = 20,
  pixelRatio = 2.5
}) => {
  const font = await loadWebfontForCanvas('/Atlantic-Medium.woff');

  // First we need to make a canvas to measure the text in
  const measureCanvas = document.createElement('canvas');
  const measureCtx = measureCanvas.getContext('2d');

  measureCtx.font = `${size}px ${font}`;
  const metrics = measureCtx.measureText(text);

  const width = maxWidth || Math.ceil(metrics.width);
  const height = size + marginBottom;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = width * pixelRatio;
  canvas.height = height * pixelRatio;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  if (backgroundColor !== 'transparent') {
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, width, height);
  }

  ctx.fillStyle = color;
  ctx.font = `${size}px ${font}`;

  ctx.fillText(text, 0, size);

  return canvas.toDataURL('image/png', 1);
};

const TextToImage = ({
  text,
  size = 45,
  color,
  backgroundColor = 'transparent',
  width = '100%',
  maxWidth,
  textWidth,
  style
}) => {
  // Remove HTML tags from the text
  const cleanedText = text.replace(/<[^>]*>/g, '');

  // Remove any unit from the size
  const cleanedSize = parseFloat(size.replace(/[^0-9]/g, ''));

  // Remove unit from width
  let unitLessWidth = parseInt(width.replace(/px$/, ''));
  unitLessWidth = Math.min(
    unitLessWidth,
    text.length * MAX_WIDTH_PER_CHARACTER
  );

  const image = useImageGenerator(imageGenerator)({
    text: cleanedText,
    size: cleanedSize,
    color: color,
    backgroundColor: backgroundColor,
    maxWidth: textWidth
  });

  return (
    <img
      alt={text}
      src={image}
      width={unitLessWidth}
      style={{
        width: unitLessWidth,
        maxWidth,
        ...style
      }}
    />
  );
};

export default TextToImage;
