import React from 'react';

import { emailCaption } from '../../constants/email';

const Button = ({
  label,
  href,
  color = 'black',
  background = 'white',
  ...rest
}) => {
  const width = '110px';

  return (
    <a
      href={href}
      className="button"
      style={{
        ...emailCaption,
        minWidth: width,
        color: color,
        fontWeight: 'bold',
        textTransform: 'lowercase',
        backgroundColor: background,
        borderColor: `${color}`,
        borderRadius: '1000px'
      }}
      {...rest}
    >
      {label}
    </a>
  );
};

export default Button;
