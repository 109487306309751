import React from 'react';
import useImage from '../../util/useImage';

import Block from './Block';
import Button from './Button';
import Paragraph from './Paragraph';
import VerticalSpacer from './VerticalSpacer';
import TwoColumns from './TwoColumns';

import {
  emailHeadline,
  emailCaption,
  emailSpacingSmall,
  emailSpacingLarge,
  emailBodyText
} from '../../constants/email';

const Conversation = ({ theme, headline, text, meta, image, url }) => {
  const { cardLightBackground, cardLightText, cardLightTitle } = theme;

  const preview = useImage(image, {
    maxWidth: 340
  });

  return (
    <Block
      title="Una conversación remota"
      background={cardLightBackground}
      color={cardLightText}
    >
      <TwoColumns padding={emailSpacingLarge}>
        <div>
          <h3
            style={{
              ...emailHeadline,
              color: cardLightTitle
            }}
          >
            {headline}
          </h3>
          <VerticalSpacer space={emailSpacingSmall} />
          <Paragraph style={emailBodyText}>{text}</Paragraph>
          <VerticalSpacer space={emailSpacingLarge} />
          <Button
            href={url}
            label="Ir a la conversación"
            color={cardLightText}
            background={cardLightBackground}
          />
          <VerticalSpacer space={emailSpacingLarge} />
        </div>
        <div style={{ marginTop: emailSpacingLarge }}>
          {preview && (
            <img
              src={preview}
              style={{ width: '340px', display: 'block', maxWidth: '100%' }}
              className="image"
              width="340"
              height="auto"
              alt=""
            />
          )}
          <div
            style={{ ...emailCaption, marginTop: emailSpacingSmall }}
            dangerouslySetInnerHTML={{ __html: meta }}
          />
        </div>
      </TwoColumns>
    </Block>
  );
};

export default Conversation;
