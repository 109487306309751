import {
  prepareHtml,
  packageToZip
} from '@designsystemsinternational/email/browser';

export const wrapperFactory = bgColor => body =>
  `<!doctype html>
<html
  lang="es"
  xml:lang="es"
  dir="ltr"
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <title>Refracciones</title>
    <!--[if mso]>
      <style type="text/css">
        table {
          border-collapse: collapse;
          border: 0;
          border-spacing: 0;
          margin: 0;
          mso-table-lspace: 0pt !important;
          mso-table-rspace: 0pt !important;
        }
        img {
          display: block !important;
        }
        div, td {padding:0;}
        div {margin:0 !important;}
        u + .body .gmail-blend-screen { background:#000; mix-blend-mode:screen; }
        u + .body .gmail-blend-difference { background:#000; mix-blend-mode:difference; }
        .notMso {
          display: none;
          mso-hide: all;
        }
      </style>
      <noscript>
        <xml>
          <o:OfficeDocumentSettings>
            <o:PixelsPerInch>96</o:PixelsPerInch>
          </o:OfficeDocumentSettings>
        </xml>
      </noscript>
    <![endif]-->
    <meta name="x-apple-disable-message-reformatting" />
    <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width" />
    <meta name="color-scheme" content="light" />
    <meta name="supported-color-schemes" content="light" />

    <meta name="format-detection" content="telephone=no">
    <meta name="format-detection" content="date=no">
    <meta name="format-detection" content="address=no">
    <meta name="format-detection" content="email=no">

    <style>
      a[x-apple-data-detectors] {
        color: inherit !important;

        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
        text-decoration: inherit !important;
      }

      a {text-decoration: none;}
      
      #MessageViewBody a:not([style]) {
        color: inherit !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
        text-decoration: inherit !important;
      }

body,
table,
td,
p,
a {
  -ms-text-size-adjust: 100% !important;
  -webkit-text-size-adjust: 100% !important;
}

table,
tr,
td {
  border-spacing: 0 !important;
  mso-table-lspace: 0px !important;
  mso-table-rspace: 0pt !important;
  border-collapse: collapse !important;
  mso-line-height-rule: exactly !important;
}

/* GMAIL TARGETING */
u + .body .hideOnGmail {
  display: none !important;
}

u + .body .showOnGmail {
  display: block !important;
}

u + .body .button {
  border-color: currentcolor !important;
}

/* HACK TO BRING OTHER STYLES TO CAPABLE CLIENTS */
@media screen and (min-width: 1px) {
  .button {
    display: inline-block !important;
    border-style: solid !important;
    border-width: 1px !important;
    padding: 5px 10px !important;
    text-align: center !important;
    text-decoration: none !important;
    font-weight: 400 !important;
  }
}

@media screen and (min-width: 800px) {
  .headline {
    font-size: 32px !important;
  }
}

@media screen and (max-width: 800px) {
  .column {
    display: block !important;
    width: 100% !important;
    height: auto !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .block {
    height: auto !important;
  }

  .hideOnMobile {
    display: none !important;
  }

  .showOnMobile {
    display: block !important;
  }

  .image {
    min-width: 100% !important;
  }

  .centerOnMobile {
    display: block !important;
    text-align: center !important;
    margin: 0 auto !important;
  }

  .stack {
    width: 100% !important;
    display: block !important;
    margin-bottom: 30px !important;
  }

  .row {
    width: auto !important;
    max-width: 100% !important;
  }

  .container {
    padding: 0 !important;
    width: 100% !important;
  }

  .button {
    min-width: 90px !important;
  }
}
    </style>
  </head>
  <body
    bgcolor="${bgColor}"
    class="body"
    style="padding:0; margin: 0; background-color: ${bgColor}; word-spacing: normal; direction: ltr;" width="100%"
  >
    ${body}
  </body>
</html>`;

/**
 * A pre-configured version to prepare the email markup with the correct
 * template
 */
export const prepareEmailMarkup = async (markup, wrapper) => {
  const preparedHtml = await prepareHtml(markup);
  return wrapper(preparedHtml);
};

/**
 * Packages the HTML into a zip file and returns a function to download it.
 */
export const createZipDownload = async (html, name) => {
  const download = await packageToZip(html, { name });
  return download;
};

export const publishToMailchimp = async ({ body, id, onError, onSuccess }) => {
  if (!body) return onError('No email HTML provided');
  if (!id) return onError('No Mailchimp Campaign ID provided');

  const endpoint = `${process.env.GATSBY_PUBLISH_URL}?id=${id}`;

  fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain'
    },
    body: body
  })
    .then(async res => {
      if (res.status === 200) {
        return onSuccess(`Pushed to Mailchimp Campaign ${id}`);
      } else {
        const data = await res.json();
        return onError(`Error pushing to Mailchimp: ${data.message}.`);
      }
    })
    .catch(e => {
      return onError(e.toString());
    });
};
