import React from 'react';
import isChrome from '@braintree/browser-detection/is-chrome';

import locale from '../../../content/locale';

import * as css from './BrowserBanner.module.css';

function BrowserBanner() {
  if (typeof window === 'undefined') {
    return null;
  } else {
    const supportedBrowser = isChrome();
    return supportedBrowser ? null : (
      <div className={css.root}>{locale.browserHint}</div>
    );
  }
}

export default BrowserBanner;
