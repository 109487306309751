import React from 'react';

import { useSiteMetaData } from '../../util/queries/site';
import useImage from '../../util/useImage';

import Table, { Tr, Td } from './Table';

import { emailSpacingLarge, emailCaption } from '../../constants/email';

const FooterLink = ({ target, image }) => {
  const icon = useImage({
    src: image,
  }, { format: 'image/png', maxWidth: 24 });

  return (
    <Td verticalAlign="middle">
      <Table>
        <Tr>
          <Td width={32} verticalAlign="middle">
            <a
              className="centerOnMobile"
              style={{
                color: 'black',
                textDecoration: 'none',
                display: 'inline-block',
                verticalAlign: 'middle',
                ...emailCaption
              }}
              href={target}
            >
              <img
                src={icon}
                width="24"
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  width: '24px',
                  marginRight: '8px'
                }}
                alt=""
              />
            </a>
          </Td>
        </Tr>
      </Table>
    </Td>
  );
};

const Footer = ({ background }) => {
  const { socialMedia } = useSiteMetaData();

  const btgLogo = useImage(
    {
      src: '/btg.png'
    },
    { maxWidth: 130, format: 'image/png' }
  );

  const uaiLogo = useImage(
    {
      src: '/uai-logo.png'
    },
    { maxWidth: 130, format: 'image/png' }
  );

  return (
    <div
      style={{ marginTop: emailSpacingLarge, paddingBottom: emailSpacingLarge }}
    >
      <Table verticalAlign="middle" background={background}>
        <Tr>
          <Td width="60%" style={{ width: '60%' }} className="stack">
            <Table verticalAlign="middle" background={background}>
              <Tr>
                <Td width="50%" style={{ width: '50%' }}>
                  <img
                    src={btgLogo}
                    width="130"
                    className="centerOnMobile"
                    style={{ width: '130px', maxWidth: '100%' }}
                    alt=""
                  />
                </Td>
                <Td width="50%" style={{ width: '50%' }}>
                  <img
                    className="centerOnMobile"
                    src={uaiLogo}
                    width="100"
                    style={{ width: '100px', maxWidth: '100%' }}
                    alt=""
                  />
                </Td>
              </Tr>
            </Table>
          </Td>
          <Td
            width="40%"
            style={{ width: '40%' }}
            verticalAlign="middle"
            horizontalAlign="right"
            className="stack"
          >
            <Table>
              <Tr>
                <FooterLink
                  label="Instagram"
                  target={socialMedia.instagram}
                  image={'/instagram.png'}
                />
                <FooterLink
                  label="LinkedIn"
                  target={socialMedia.linkedin}
                  image={'/linkedin.png'}
                />
                <FooterLink
                  label="Twitter"
                  target={socialMedia.twitter}
                  image={'/twitter.png'}
                />
                <FooterLink
                  label="Spotify"
                  target={socialMedia.spotify}
                  image={'/spotify.png'}
                />
                <FooterLink
                  label="Youtube"
                  target={socialMedia.youtube}
                  image={'/youtube.png'}
                />
              </Tr>
            </Table>
          </Td>
        </Tr>
      </Table>
    </div>
  );
};

export default Footer;
