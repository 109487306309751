import * as yup from 'yup';

import { slugRegex, slugWarning, yupColor } from './utils';

export const baseColors = {
  pageBackground: '#88869b',
  highlightBackground: '#d94d2e',
  highlightText: '#d94d2e',
  artworkBackground: '#332644',
  mixBackground: '#a9b595',
  mixBackground2: '#748d5c'
};

export const baseColorLabels = {
  pageBackground: 'Fondo de página',
  highlightBackground: 'Fondo destacado',
  highlightText: 'Texto destacado',
  artworkBackground: 'Fondo obra',
  mixBackground: 'Fondo mix',
  mixBackground2: 'Fondo mix 2'
};

const ByLineValidationSchema = yup.object().shape({
  name: yup.string(),
  role: yup.string(),
  company: yup.string()
});

// Form validation schema describe by yup
const IssueValidationSchema = yup.object().shape({
  draft: yup.boolean().default(true),
  number: yup
    .number('El número de edición es obligatorio')
    .required('El número de edición es obligatorio'),
  title: yup.string().required(),
  initialShowAbout: yup.boolean().default(false),
  date: yup.date().default(() => new Date()),
  slug: yup.string().matches(slugRegex, slugWarning).required(),
  colors: yup.object().shape({
    pageBackground: yupColor.default(baseColors.pageBackground),
    highlightBackground: yupColor.default(baseColors.highlightBackground),
    highlightText: yupColor.default(baseColors.highlightText),
    artworkBackground: yupColor.default(baseColors.artworkBackground),
    mixBackground: yupColor.default(baseColors.mixBackground),
    mixBackground2: yupColor.default(baseColors.mixBackground2)
  }),
  email: yup.object().shape({
    campaignId: yup.string(),
    subject: yup.string(),
    excerpt: yup.string(),
    showLetter: yup.boolean().default(false),
    title: yup.string(),
    text: yup.string(),
    byLine: ByLineValidationSchema
  }),
  intro: yup.object().shape({
    show: yup.boolean().default(true),
    headline: yup.string(),
    text: yup.string(),
    email: yup.string(),
    imagePosition: yup.string().oneOf(['', 'top', 'center', 'bottom']),
    imageCaption: yup.string()
  }),
  quote: yup.object().shape({
    show: yup.boolean().default(true),
    text: yup.string(),
    author: yup.object().shape({
      name: yup.string(),
      subline: yup.string()
    })
  }),
  book: yup.object().shape({
    show: yup.boolean().default(true),
    headline: yup.string(),
    byLine: ByLineValidationSchema,
    intro: yup.string(),
    text: yup.string(),
    book: yup.object().shape({
      title: yup.string(),
      author: yup.string(),
      publisher: yup.string(),
      info: yup.string(),
      cta: yup.string(),
      url: yup.string().url(),
      shadow: yup.boolean().default(true)
    })
  }),
  podcast: yup.object().shape({
    show: yup.boolean().default(true),
    title: yup.string(),
    byLine: ByLineValidationSchema,
    duration: yup.number().nullable(true),
    episodeId: yup.string(),
    applePodcastsUrl: yup.string(),
    spotifyUrl: yup.string(),
    intro: yup.string(),
    email: yup.string()
  }),
  artwork: yup.object().shape({
    show: yup.boolean().default(true),
    title: yup.string(),
    intro: yup.string(),
    info: yup.array().of(
      yup.object().shape({
        label: yup.string(),
        description: yup.string()
      })
    )
  }),
  mix: yup.object().shape({
    show: yup.boolean().default(true),
    title: yup.string(),
    byLine: ByLineValidationSchema,
    intro: yup.string(),
    titleRight: yup.boolean().default(true),
    email: yup.string(),
    imagePosition: yup
      .string()
      .oneOf(['top', 'center', 'bottom'])
      .default('bottom'),
    highlightBlock: yup.object().shape({
      label: yup.string(),
      labelBackground: yupColor.default('#ffffff'),
      headline: yup.string(),
      text: yup.string(),
      cta: yup.string(),
      url: yup.string().url()
    }),
    blocks: yup.array().of(
      yup.object().shape({
        label: yup.string(),
        labelBackground: yupColor.default('#ffffff'),
        headline: yup.string(),
        text: yup.string(),
        cta: yup.string(),
        url: yup.string().url()
      })
    )
  }),
  conversation: yup.object().shape({
    show: yup.boolean().default(true),
    title: yup.string(),
    text: yup.string(),
    meta: yup.string(),
    duration: yup.number().nullable(true),
    videoId: yup.string()
  }),
  playlist: yup.object().shape({
    show: yup.boolean().default(true),
    title: yup.string(),
    byLine: ByLineValidationSchema,
    text: yup.string(),
    spotifyUrl: yup.string(),
    playlistId: yup.string()
  })
});

const sectionLabels = {
  issue: 'Edición',
  email: 'Email',
  intro: 'Introducción',
  quote: 'Cita',
  book: 'Libro',
  podcast: 'Podcast',
  artwork: 'Obra',
  mix: 'Mix',
  conversation: 'Conversación',
  playlist: 'Playlist'
};

// Form Schema describing the input fields to use
const IssueFormSchema = [
  {
    label: 'Número',
    name: 'number',
    type: 'number',
    defaultValue: 0
  },
  {
    name: 'title',
    label: 'Título',
    type: 'text'
  },
  {
    name: 'slug',
    type: 'text'
  },
  {
    name: 'date',
    label: 'Fecha',
    type: 'date'
  },
  {
    label: 'Draft',
    name: 'draft',
    type: 'boolean',
    defaultValue: true
  },
  {
    name: 'initialShowAbout',
    label: 'Iniciar con about expandido',
    type: 'boolean'
  },
  {
    name: 'colors',
    label: 'Paleta de color',
    type: 'colors',
    baseColors
  },
  {
    name: 'email.campaignId',
    label: 'campaignId (Mailchimp)',
    type: 'text'
  },
  {
    name: 'email.subject',
    label: 'Asunto',
    type: 'text'
  },
  {
    name: 'email.excerpt',
    label: 'Texto de preview',
    type: 'text'
  },
  {
    name: 'email.showLetter',
    label: 'Mostrar Carta',
    type: 'boolean'
  },
  {
    name: 'email.title',
    type: 'text',
    label: 'Título',
    multiline: true,
    html: true
  },
  {
    name: 'email.text',
    type: 'text',
    label: 'Texto',
    multiline: true,
    html: true
  },
  {
    name: 'email.byLine',
    label: 'Firma',
    type: 'person',
    schema: [
      {
        name: 'name',
        type: 'text'
      },
      {
        name: 'role',
        type: 'text'
      },
      {
        name: 'company',
        type: 'text'
      }
    ]
  },
  {
    name: 'intro.show',
    label: 'Mostrar',
    type: 'boolean'
  },
  {
    name: 'intro.headline',
    label: 'Título',
    type: 'text'
  },
  {
    name: 'intro.text',
    label: 'Texto',
    type: 'text',
    multiline: true,
    html: true
  },
  {
    name: 'intro.image',
    label: 'Imagen',
    type: 'image'
  },
  {
    name: 'intro.imagePosition',
    label: 'Posición de imagen',
    type: 'select',
    options: ['top', 'center', 'bottom']
  },
  { name: 'intro.imageCaption', label: 'Descripción de imagen', type: 'text' },
  {
    name: 'intro.imageEmail',
    label: 'Imagen (email)',
    type: 'image'
  },
  {
    name: 'intro.email',
    label: 'Texto Email',
    type: 'text',
    multiline: true,
    html: true
  },

  {
    name: 'quote.show',
    label: 'Mostrar',
    type: 'boolean'
  },
  {
    name: 'quote.text',
    label: 'Texto',
    type: 'text',
    multiline: true
  },
  {
    name: 'quote.author.name',
    label: 'Autor',
    type: 'text'
  },
  {
    name: 'quote.author.subline',
    label: 'Fuente',
    type: 'text'
  },
  {
    name: 'book.show',
    label: 'Mostrar',
    type: 'boolean'
  },
  {
    name: 'book.headline',
    label: 'Título',
    type: 'text'
  },
  {
    name: 'book.intro',
    label: 'Bajada',
    type: 'text',
    multiline: true,
    html: true
  },
  {
    name: 'book.text',
    label: 'Texto',
    type: 'text',
    multiline: true,
    html: true
  },
  {
    name: 'book.byLine',
    type: 'person'
  },
  {
    name: 'book.book.title',
    label: 'Título del libro',
    type: 'text'
  },
  {
    name: 'book.book.author',
    label: 'Autor del libro',
    type: 'text'
  },
  {
    name: 'book.book.publisher',
    label: 'Editorial del libro',
    type: 'text'
  },
  {
    name: 'book.book.info',
    label: 'Info del libro',
    type: 'text'
  },
  {
    name: 'book.book.cta',
    label: 'Etiquta de link',
    type: 'text'
  },
  {
    name: 'book.book.url',
    label: 'Link',
    type: 'text'
  },
  {
    name: 'book.book.image',
    label: 'Portada',
    type: 'image'
  },
  {
    name: 'book.book.shadow',
    label: 'Sombra Portada',
    type: 'boolean'
  },
  {
    name: 'podcast.show',
    label: 'Mostrar',
    type: 'boolean'
  },
  {
    name: 'podcast.title',
    label: 'Título',
    type: 'text'
  },
  {
    name: 'podcast.byLine',
    type: 'person'
  },
  {
    name: 'podcast.duration',
    label: 'Duración (minutos)',
    type: 'number'
  },

  {
    name: 'podcast.intro',
    label: 'Introducción',
    type: 'text',
    multiline: true,
    html: true
  },
  {
    name: 'podcast.email',
    label: 'Texto Email',
    type: 'text',
    multiline: true
  },
  {
    name: 'podcast.image',
    label: 'Imagen (email)',
    type: 'image'
  },
  {
    name: 'podcast.episodeId',
    label: 'episodeId (spotify)',
    type: 'text'
  },
  {
    name: 'podcast.spotifyUrl',
    type: 'text'
  },
  {
    name: 'podcast.applePodcastsUrl',
    type: 'text'
  },

  {
    name: 'artwork.show',
    label: 'Mostrar',
    type: 'boolean'
  },
  {
    name: 'artwork.title',
    label: 'Título',
    type: 'text'
  },
  {
    name: 'artwork.image',
    label: 'Imagen',
    type: 'image'
  },
  {
    name: 'artwork.imageEmail',
    label: 'Imagen Email (opcional)',
    type: 'image'
  },
  {
    name: 'artwork.intro',
    label: 'Introducción',
    type: 'text',
    multiline: true,
    html: true
  },
  {
    name: 'artwork.info',
    label: 'Ficha de la obra',
    type: 'artworkInfo',
    schema: [
      {
        name: 'label',
        type: 'text'
      },
      {
        name: 'description',
        type: 'text'
      }
    ]
  },
  {
    name: 'mix.show',
    label: 'Mostrar',
    type: 'boolean'
  },
  {
    name: 'mix.title',
    label: 'Título',
    type: 'text'
  },
  {
    name: 'mix.byLine',
    type: 'person',
    schema: [
      {
        name: 'name',
        type: 'text'
      },
      {
        name: 'role',
        type: 'text'
      },
      {
        name: 'company',
        type: 'text'
      }
    ]
  },

  {
    name: 'mix.intro',
    label: 'Introducción',
    type: 'text',
    multiline: true,
    html: true
  },
  {
    name: 'mix.titleRight',
    label: 'Título e intro a la derecha',
    type: 'boolean'
  },

  {
    name: 'mix.image',
    label: 'Imagen',
    type: 'image'
  },

  {
    name: 'mix.email',
    label: 'Texto email',
    type: 'text',
    multiline: true,
    html: true
  },
  {
    name: 'mix.highlightBlock.label',
    label: 'Bloque destacado: Etiqueta',
    type: 'text'
  },
  {
    name: 'mix.highlightBlock.labelBackground',
    label: 'Bloque destacado: Color de etiqueta',
    type: 'themeColorSelect'
  },
  {
    name: 'mix.highlightBlock.headline',
    label: 'Bloque destacado: Título',
    type: 'text'
  },
  {
    name: 'mix.highlightBlock.text',
    label: 'Bloque destacado: Texto',
    type: 'text',
    multiline: true,
    html: true
  },
  {
    name: 'mix.highlightBlock.cta',
    label: 'Bloque destacado: Texto link',
    type: 'text'
  },
  {
    name: 'mix.highlightBlock.url',
    label: 'Bloque destacado: Url',
    type: 'text'
  },
  {
    name: 'mix.highlightBlock.image',
    label: 'Bloque destacado: Imagen',
    type: 'image'
  },
  {
    name: 'mix.highlightBlock.shadow',
    label: 'Bloque destacado: Sombra imagen',
    type: 'boolean'
  },
  {
    name: 'mix.blocks',
    label: 'Bloques',
    type: 'mixBlock',
    schema: [
      {
        name: 'label',
        type: 'text'
      },
      {
        name: 'labelBackground',
        type: 'themeColorSelect'
      },
      {
        name: 'headline',
        type: 'text'
      },
      {
        name: 'text',
        type: 'text',
        multiline: true,
        html: true
      },
      {
        name: 'url',
        type: 'text'
      },
      {
        name: 'cta',
        type: 'text'
      }
    ]
  },
  {
    name: 'conversation.show',
    label: 'Mostrar',
    type: 'boolean'
  },
  {
    name: 'conversation.title',
    label: 'Título',
    type: 'text'
  },
  {
    name: 'conversation.image',
    label: 'Imagen',
    type: 'image'
  },
  {
    name: 'conversation.text',
    label: 'Texto',
    type: 'text',
    multiline: true,
    html: true
  },
  {
    name: 'conversation.meta',
    label: 'Ficha',
    type: 'text',
    html: true,
    multiline: true
  },
  {
    name: 'conversation.duration',
    label: 'Duración',
    type: 'number'
  },
  {
    name: 'conversation.videoId',
    label: 'videoId (YouTube)',
    type: 'text'
  },
  { name: 'playlist.show', label: 'Mostrar', type: 'boolean' },
  { name: 'playlist.title', label: 'Título', type: 'text' },
  {
    name: 'playlist.byLine',
    type: 'person',
    schema: [
      {
        name: 'name',
        type: 'text'
      },
      {
        name: 'role',
        type: 'text'
      },
      {
        name: 'company',
        type: 'text'
      }
    ]
  },
  {
    name: 'playlist.intro',
    label: 'Introducción',
    type: 'text',
    multiline: true,
    html: true
  },
  {
    name: 'playlist.image',
    label: 'Imagen (email)',
    type: 'image'
  },
  {
    name: 'playlist.spotifyUrl',
    label: 'Playlist URL (Spotify)',
    type: 'text'
  }
];

const CreateIssueFormSchema = [
  {
    name: 'id',
    type: 'text',
    helperText:
      'Este es el ID de la edición y corresponde al nombre en el listado del admin. Una vez creado no se puede cambiar.'
  },
  ...IssueFormSchema
];

const CreateIssueValidationSchema = IssueValidationSchema.shape({
  id: yup.string().matches(slugRegex, slugWarning).required()
});

export {
  IssueFormSchema,
  sectionLabels,
  IssueValidationSchema,
  CreateIssueFormSchema,
  CreateIssueValidationSchema
};
