import React from 'react';
import useImage from '../../util/useImage';

import Block from './Block';
import Button from './Button';
import Paragraph from './Paragraph';
import VerticalSpacer from './VerticalSpacer';

import Table, { Td, Tr } from './Table';

import {
  emailHeadline,
  emailCaption,
  emailSpacingSmall,
  emailSpacingLarge,
  emailBodyText
} from '../../constants/email';

const Mix = ({
  theme,
  headline,
  byLine,
  text,
  image,
  url,
  imagePosition = 'bottom'
}) => {
  const { cardMixBackground, cardMixBackground2, cardMixText } = theme;

  const imageWrapperPaddingTop =
    imagePosition === 'top' ? '0' : emailSpacingSmall;
  const imageWrapperPaddingBottom =
    imagePosition === 'bottom' ? '0' : emailSpacingSmall;

  const imageWidth =
    image.width && image.height
      ? image.height >= image.width
        ? 150
        : (image.width / image.height) * 150
      : null;
  const imageHeight =
    image.width && image.height
      ? (image.height / image.width) * imageWidth
      : null;

  const width = imageWidth ?? 200;

  const loadedImg = useImage(image, {
    maxWidth: width,
    format: 'image/png'
  });

  return (
    <Block
      title="Un mix"
      background={cardMixBackground}
      color={cardMixText}
      bodyPadding={0}
    >
      <Table>
        <Tr>
          <Td
            width="40%"
            verticalAlign={imagePosition}
            style={{
              paddingLeft: emailSpacingLarge,
              paddingRight: emailSpacingLarge,
              paddingTop: emailSpacingLarge,
              paddingBottom: emailSpacingLarge
            }}
            className="hideOnMobile"
          >
            <Table horizontalAlign="center" background={cardMixBackground2}>
              <Tr>
                <Td
                  width="200px"
                  horizontalAlign="center"
                  verticalAlign={imagePosition}
                  style={{
                    paddingLeft: emailSpacingSmall,
                    paddingRight: emailSpacingSmall,
                    paddingTop: imageWrapperPaddingTop,
                    paddingBottom: imageWrapperPaddingBottom
                  }}
                >
                  {loadedImg && (
                    <img
                      width={width}
                      height={imageHeight}
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: `${width}px`,
                        height: `${imageHeight}px`,
                        maxWidth: '100%',
                        lineHeight: 0
                      }}
                      src={loadedImg}
                      alt={image.alt}
                    />
                  )}
                </Td>
              </Tr>
            </Table>
          </Td>
          <Td
            width="60%"
            style={{
              paddingRight: emailSpacingLarge,
              paddingBottom: emailSpacingLarge,
              paddingLeft: emailSpacingLarge
            }}
          >
            <VerticalSpacer
              space={emailSpacingLarge}
              style={{
                display: 'none'
              }}
              className="showOnMobile"
            />
            <h3 style={{ ...emailHeadline, color: cardMixText }}>{headline}</h3>
            <VerticalSpacer space={emailSpacingSmall} />
            <div
              style={{
                ...emailCaption
              }}
            >
              Por {byLine.name}
              <br />
              {byLine.role}
              {byLine.role && byLine.company && ', '}
              {byLine.company}
            </div>
            <VerticalSpacer space={emailSpacingLarge} />

            <Paragraph style={emailBodyText}>{text}</Paragraph>
            <VerticalSpacer space={emailSpacingLarge} />
            <Button
              label="Ir al mix"
              href={url}
              color={cardMixText}
              background={cardMixBackground}
            />
          </Td>
        </Tr>
      </Table>
    </Block>
  );
};

export default Mix;
