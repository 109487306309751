import * as React from 'react';

import {
  List,
  Datagrid,
  Create,
  Button,
  CreateButton,
  SimpleForm,
  TextField,
  DateField,
  FormDataConsumer,
  Toolbar,
  SaveButton,
  TopToolbar,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  useRecordContext,
  FunctionField
} from 'react-admin';

import PreviewIcon from '@mui/icons-material/Preview';
import ProductionIcon from '@mui/icons-material/DomainVerification';
import { PRODUCTION_URL, STAGING_URL } from './constants';

export const ReleaseIcon = PreviewIcon;

const ListActions = () => {
  return (
    <TopToolbar style={{ justifyContent: 'space-between', width: '100%' }}>
      <CreateButton label={'Trigger new release'} />
      <div style={{ flexGrow: 1 }} />

      <Button
        variant="outlined"
        label={'Open preview '}
        href={STAGING_URL}
        target="_blank"
        rel="noreferrer"
      >
        <PreviewIcon />
      </Button>

      <Button
        variant="contained"
        label={'Open production '}
        href={PRODUCTION_URL}
        target="_blank"
        rel="noreferrer"
      >
        <ProductionIcon />
      </Button>
    </TopToolbar>
  );
};

export const ReleaseList = props => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      queryOptions={{ refetchInterval: 7000 }}
    >
      <Datagrid>
        <TextField source="name" label="Nombre" />
        <DateField source="createdAt" label="Fecha" showTime />
        <FunctionField
          render={release => (release.prerelease ? 'Preview' : 'Production')}
        />
        <ReleaseStatus label="Status" />
      </Datagrid>
    </List>
  );
};

const ReleaseCreateToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveButton alwaysEnable label={'Release'} />
      <div style={{ width: 30 }}></div>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <FunctionField
              render={release =>
                `This action will trigger a ${formData.prerelease ? 'Preview' : 'Production'
                } Release`
              }
            />
          );
        }}
      </FormDataConsumer>
    </Toolbar>
  );
};

export const ReleaseCreate = props => (
  <Create
    title="Trigger a Release"
    {...props}
    transform={release => ({
      ...release,
      name: release.prerelease
        ? 'Preview website release'
        : 'Production website release'
    })}
  >
    <SimpleForm
      style={{ maxWidth: 1000 }}
      record={{ name: 'Website release', prerelease: true }}
      toolbar={<ReleaseCreateToolbar />}
    >
      <BooleanInput label="Preview" source="prerelease" />
    </SimpleForm>
  </Create>
);

const ReleaseStatus = () => {
  const record = useRecordContext();
  if (!record || !record.action) {
    return null;
  }
  const { action } = record;

  if (action.status === 'queued') {
    return <span>Queued...</span>;
  } else if (action.status === 'in_progress') {
    return <span>Deploying...</span>;
  } else if (action.status === 'completed') {
    if (action.conclusion === 'success') {
      return <span>Published</span>;
    } else if (action.conclusion === 'failure') {
      return <span>Publish failed</span>;
    }
  }

  return null;
};
